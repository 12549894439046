var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab_content_area", attrs: { id: "tab4" } }, [
    _vm._m(0),
    _c("div", { staticClass: "container_tbl_wrap" }, [
      _c("div", { staticClass: "tbl_tit" }, [
        _vm._v(_vm._s(_vm.$t("msg.NEWB010P050.007"))),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img_wrap", staticStyle: { height: "300px" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/images/trans/con_frsr.png") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tbl_wrap" }, [
      _c("table", { staticClass: "tbl_col" }, [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "20%" } }),
          _c("col", { staticStyle: { width: "auto" } }),
          _c("col", { staticStyle: { width: "20%" } }),
          _c("col", { staticStyle: { width: "20%" } }),
          _c("col", { staticStyle: { width: "20%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Type of "),
              _c("br"),
              _vm._v("Container"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Internal"),
              _c("br"),
              _vm._v("Dimension"),
              _c("br"),
              _vm._v("(mm)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Container"),
              _c("br"),
              _vm._v("Tare Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Pay Load"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Gross Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("20FR")]),
            _c("td", [
              _vm._v("L: 5,612"),
              _c("br"),
              _vm._v("W: 2,227"),
              _c("br"),
              _vm._v("H: 2,213"),
            ]),
            _c("td", [_vm._v("2,740")]),
            _c("td", [_vm._v("31,260")]),
            _c("td", [_vm._v("34,000")]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("40FR")]),
            _c("td", [
              _vm._v("L: 11,770"),
              _c("br"),
              _vm._v("W: 2,380"),
              _c("br"),
              _vm._v("H: 1,955"),
            ]),
            _c("td", [_vm._v("5,600")]),
            _c("td", [_vm._v("39,400")]),
            _c("td", [_vm._v("45,000")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }