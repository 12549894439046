<template>
  <div id="tab4" class="tab_content_area">
    <div class="img_wrap" style="height:300px;">
      <img src="../../../assets/images/trans/con_frsr.png">
    </div>
    <div class="container_tbl_wrap">
      <div class="tbl_tit">{{ $t('msg.NEWB010P050.007') }}</div><!-- FLAT RACK CONTAINER -->
      <div class="tbl_wrap">
        <table class="tbl_col">
          <colgroup>
            <col style="width:20%">
            <col style="width:auto">
            <col style="width:20%">
            <col style="width:20%">
            <col style="width:20%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Type of <br>Container</th>
              <th scope="col">Internal<br>Dimension<br>(mm)</th>
              <th scope="col">Container<br>Tare Weight<br>(Kg)</th>
              <th scope="col">Max <br>Pay Load<br>(Kg)</th>
              <th scope="col">Max <br>Gross Weight<br>(Kg)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">20FR</th>
              <td>L: 5,612<br>W: 2,227<br>H: 2,213</td>
              <td>2,740</td>
              <td>31,260</td>
              <td>34,000</td>
            </tr>
            <tr>
              <th scope="row">40FR</th>
              <td>L: 11,770<br>W: 2,380<br>H: 1,955</td>
              <td>5,600</td>
              <td>39,400</td>
              <td>45,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerFR'
}
</script>

<style scoped>

</style>
